import React from 'react';
import {withStyles} from '@material-ui/core';
import {withFirebase} from '../Firebase';
import styles from '../styles/SignOutPageStyles'

const SignOutButton=({firebase, text, classes})=> (

    <button type="button" onClick={firebase.doSignOut} className={classes.Button}>
        {text}
    </button>

)

export default withFirebase(withStyles(styles)(SignOutButton))