import sizes from "./sizes"

export default {
    classSmallHeading: {
        color:"#434343",
        fontWeight:"bold",
        fontFamily:"Helvetica Neue, sans-serif",
        fontSize:"1.5rem !important",
        [sizes.down("xs")]:{
            fontSize:"1rem !important"
        }
    },
    classText:{
        color:"#6C6C6C",
        fontSize:"1.5rem !important",
        lineHeight:"2rem",
        fontFamily:"Helvetica Neue, sans-serif",
        textAlign: "left",
        [sizes.down("xs")]:{
            fontSize:"1rem !important",
            lineHeight:"1rem"
        }
    }
}
