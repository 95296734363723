import sizes from "./sizes"

export default{
    AboutMe:{
        padding:"1rem"
    },
    AboutHeading:{
        fontWeight:"bold",
        color:"#434343",
        fontFamily:"Helvetica Neue, sans-serif",
        fontSize:"2vw",
        padding:"1rem",
        [sizes.down("lg")]: {
            fontSize:"5vw"
        },
        [sizes.down("xs")]:{
            fontSize:"7vw"
        }
    },
    AboutText:{
        color:"#6C6C6C",
        fontSize:"1.5rem !important",
        lineHeight:"2rem",
        fontFamily:"Helvetica Neue, sans-serif",
        [sizes.down("xs")]:{
            fontSize:"1rem !important",
            lineHeight:"1rem"
        }
    }
}


