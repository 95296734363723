import React, { useState, useLayoutEffect } from 'react';

export const ThemeContext = React.createContext({
  isDarkMode: false,
  toggle: () => {},
});

export function ThemeProvider (props) {
  // keeps state of the current chosen theme
  const [isDarkMode, setDark] = useState(window.localStorage.getItem('darkTheme'));
  // paints the app before it renders elements

  useLayoutEffect(() => {
    // check if value already present in localStorage
    const lastTheme = window.localStorage.getItem('darkTheme');
    if (lastTheme === 'true') {
      setDark(true);

    } if (!lastTheme || lastTheme === 'false') {
        setDark(false);

    }}, [isDarkMode]);

    const toggle = () => {
        // if toggle, change dark mode and set window
        setDark(!isDarkMode);
        window.localStorage.setItem('darkTheme', !isDarkMode);
    };
    return <ThemeContext.Provider value={{isDarkMode, toggle}}>
        {props.children}
    </ThemeContext.Provider>
    }

;