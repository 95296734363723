import React,{createContext, useState, useLayoutEffect} from 'react';

export const LanguageContext=createContext({
    language:'',
    currentLangData:{},
    changeLanguage:()=>{}
});


export function LanguageProvider(props) {
    // window.localStorage.getItem('appUILang')
    const [language, setLanguage] = useState('english');

    useLayoutEffect(() => {
        // get last stored language
    const lastLanguage = window.localStorage.getItem('appUILang');
    if (lastLanguage){
            setLanguage(lastLanguage);
    }},[language])
    
    const changeLanguage = (e) => {
            // if toggle, change dark mode and set window
        setLanguage(e.target.value)
            window.localStorage.setItem('appUILang', e.target.value);
        };   
    return <LanguageContext.Provider value={{language,changeLanguage}}>
        {props.children}
    </LanguageContext.Provider>
}
