import React,{useContext} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Link,Select,MenuItem,Drawer,Hidden,List, ListItem, ListItemText} from '@material-ui/core';
import {useMediaQuery} from './hooks/checkMediaQuery'
import FormControl from '@material-ui/core/FormControl';
import { withStyles} from "@material-ui/core/styles";
import InvertColorsIcon from '@material-ui/icons/InvertColors';



import {LanguageContext} from './contexts/LanguageContext.js'
import {ThemeContext} from './contexts/ThemeContext'
import {AuthUserContext} from './session'

import styles from './styles/NavBarStyles.js'

const content={
  english:{
    home:"HOME",
    about:"ABOUT",
    projects:"PROJECTS",
    contact:"CONTACT",
    members:"MEMBERS",
    account:"ACCOUNT",
    signout:"SIGN OUT"
  },
  français:{
    home:"L'ACCUEIL",
    about:"PAGE DE NOUS",
    projects:"PROJETS",
    contact:"CONTACTER",
    members:"MEMBRES",
    account:"COMPTE",
    signout:"DÉCONNEXION"

  }
}


function NavBar({classes, history}) {
  const {language, changeLanguage}= useContext(LanguageContext);
  const {home,about,projects,contact,members,account,signout}=content[language]
  // const {navbar}= useContext(LanguageContext).currentLangData;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isLarge = useMediaQuery('(min-width: 1025px)');
  const {isDarkMode,toggle} = useContext(ThemeContext);
  const handleDrawerToggle = () => {
    {!isLarge &&
    setMobileOpen(!mobileOpen);
    }
  };
  const list=['home','about', 'projects', 'contact', 'members','account', 'signout'];
  const handleSwitch=()=> {
    toggle();
  }


  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        
        {[home,about, projects, contact, members,account, signout].map((text, index) => (
          <Link underline="none" href={`/${list[index]}`} color="inherit">
          <ListItem button key={text}>
            <ListItemText key={text} primary={text}>
            </ListItemText>
          </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  
  return (

    <AuthUserContext.Consumer> 
      {authUser=> 
    <React.Fragment>      
      <CssBaseline />

      <AppBar className={classes.NavBar} color={isDarkMode?"secondary":"primary"} position="fixed">
        <Toolbar className={classes.NavBarForm}>
          <Typography className={classes.NavIcon} onClick={handleDrawerToggle} variant="h6">Mini Interface</Typography>
          <div className={classes.NavLinks}>
          <Link onClick={()=> {history.push('/')}} className={classes.NavTab} underline="none" href="/" color="inherit">
            {home}
          </Link>

          <Link onClick={()=> {history.push('/about')}} className={classes.NavTab} underline="none" href="/about" color="inherit">
            {about}
          </Link>

          <Link onClick={()=> {history.push('/projects')}} className={classes.NavTab} underline="none" href="/projects" color="inherit">
            {projects}
          </Link>

          <Link onClick={()=> {history.push('/contact')}} className={classes.NavTab} underline="none" href="/contact" color="inherit">
            {contact}
          </Link>

            <Link onClick={()=> {history.push('/members')}} className={classes.NavTab} underline="none" href="/members" color="inherit">
            {members}
          </Link>

            

          </div>
          <div>
            <InvertColorsIcon
            fontSize="large"
            style={{marginRight:"1rem", cursor:"pointer"}}
              onClick={handleSwitch}
              color={isDarkMode?"primary":"secondary"}
            />
            <FormControl>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={language}
              onChange={changeLanguage}
              style={{color:isDarkMode?"black":"white"}}
            >
              <MenuItem value="english">english</MenuItem>
              <MenuItem value="français">français</MenuItem>
            </Select>
            </FormControl>
          </div>
          <Hidden smUp implementation="css" className={classes.HiddenDrawer}>
            <Drawer
              variant="temporary"
              anchor="top"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          
        </Toolbar>
        
      </AppBar>
    </React.Fragment>
    }
  </AuthUserContext.Consumer> 
  );
}

export default withStyles(styles)(NavBar);