import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import AboutMe from './AboutMe'
import LandingPage from './LandingPage'
import Contact from './Contact'
import Projects from './Projects'
import SignInPage from './userAuth/SignInPage'
import SignUpPage from './userAuth/SignUpPage'
import AccountPage from './freelancers/AccountPage'
import HomePage from './freelancers/HomePage'
import SignOutButton from './userAuth/SignOutPage'
import PasswordForgetPage from './userAuth/PasswordForgetPage'
import MembersPage from './MembersPage'
import * as ROUTES from './constants/routes';



const Routes=()=> (
    <>
    <Switch>
    {/* Switch only finds 1 path */}
    {/* exact makes such that the path is exactly the same with the specified path */}
    {/* Difference between component and render. Better to use render if passing props in,
    as page will not be reuploaded. Otherwise no difference*/}
    <Route exact path={ROUTES.LANDING} component={LandingPage} />
    <Route exact path={ROUTES.ABOUT} component={AboutMe} />
    <Route exact path= {ROUTES.PROJECTS} component={Projects} />
    <Route exact path= {ROUTES.CONTACT} component={Contact} />
    <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
    <Route exact path={ROUTES.HOME} component={HomePage} />
    <Route exact path={ROUTES.SIGN_OUT} component={SignOutButton} />
    <Route exact path={ROUTES.MEMBERS} component={MembersPage} />
    <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    
    {/* <Route exact path='/dog/r' render={()=><Dog name 'Biscuits' />} /> */}

    {/* adding a 404 error route. Need a Switch for the path. Place the NotFound at the end */}
    <Redirect to='/' />
</Switch>
</>
)

export default Routes