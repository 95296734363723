import React, {useContext} from 'react'
import {ThemeContext} from './contexts/ThemeContext'

export default function PageContext(props){
    const {isDarkMode}=useContext(ThemeContext);
    const styles={
        backgroundColor:isDarkMode===true?"black":"#F6F6F6",
        margin:"0",
        padding:"0",

    }
    return (
        <div style={styles}>
            {props.children}
        </div>
    )
}