import sizes from "./sizes"

const styles={

    LandingPageImg:{
        width:"30%",
        [sizes.down("lg")]:{
            width:"55%"
        },
        [sizes.down("xs")]:{
            width:"70%"
        }

    },
    LandingPageContent:{
        paddingTop:"5rem",
        textAlign:"center",   
        "& h2":{
            fontWeight:"bold",
            fontFamily:"Helvetica Neue, sans-serif",
            fontSize:"3vw",
            margin:"0",
            [sizes.down("lg")]:{
                marginTop:"1rem",
                marginBottom:"1rem",
                fontSize:"10vw",
            },
            [sizes.down("xxs")]:{
                marginTop:"2rem",
                marginBottom:"2rem",
                fontSize:"10vw",
            }
        },
        "& h1":{
            fontWeight:"bold",
            fontFamily:"Helvetica Neue, sans-serif",
            color: "#F6F6F6",
            textShadow:"2px 2px 17px rgba(0,0,0,0.5)",
            fontSize: "10vw",
            margin:"0",
            [sizes.down("lg")]:{
                marginBottom:"1rem",
                fontSize:"16vw"
            },
            [sizes.down("xs")]:{
                marginBottom:"2rem"
            }
        }
    },
    LandingPageSmallText: {
        fontSize:"1vw !important",
        paddingBottom:"2rem",
        [sizes.down("lg")]:{
            fontSize:"3vw !important",
        },
        [sizes.down("xs")]:{
            fontSize:"4vw !important",
        },

    },
    LandingPageBtnTxt: {
        textDecoration:"none",
        color:"black !important",
        [sizes.down("lg")]:{
            fontSize:"2vw !important",
        },
        [sizes.down("xs")]:{
            fontSize:"3vw !important",
        }
    },
    FixedText:{
        position: "fixed",
        top:"30%",
        right:"-160px",
        transform: "rotate(-90deg)",
        fontWeight:"700",
        textTransform: "uppercase",
        fontSize:".875rem",
        letterSpacing:"1.5px",
        color:"white",
        mixBlendMode: "difference",
        [sizes.down("xs")]:{
            right:"-100px",
            fontSize:"0.5rem"
        }
    }

}

export default styles;