import React, {useContext} from 'react';
import uuid from 'uuid/v4'
import ProjectContent from './ProjectContent'
import styles from './styles/ProjectsStyles.js'
import withStyles from "@material-ui/core/styles/withStyles";
import {LanguageContext} from './contexts/LanguageContext'
import {ThemeContext} from './contexts/ThemeContext'
import Fade from 'react-reveal/Fade'

const cards={
    english:{
    content:[
        {
            imgsrc:"https://images.unsplash.com/photo-1535303311164-664fc9ec6532?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80",
            heading:"Social Media Design",
            text:"I am able to design to suit a variety of media, such as instagram stories, instagram posts and facebook posts. Follow my instagram page for some exciting developments to my designs @mini.interface.",
            credits:"Photo by Sara Kurfeß on Unsplash"
        },
        { 
            imgsrc:"https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
            heading:"Web Development",
            text:"I currently use tools such as ReactJS and NodeJS for front-end and back-end web development. Based on your request, I will be able to build simple web applications.",
            credits:"Photo by Clément H on Unsplash"
        },
        {
            imgsrc:"https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=786&q=80",
            heading:"Web Design",
            text:"I will be able to collaborate with you to come up with a responsive web design for your website. In addition, I use tools such as Bootstrap, Material UI and Semantic UI for web design.",
            credits:"Photo by Ben Kolde on Unsplash"

        }
    ]
    },
    français:{
        content:[
            {
                imgsrc:"https://images.unsplash.com/photo-1535303311164-664fc9ec6532?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80",
                heading:"Design de médias sociaux",
                text:"Je peux designer pour une variété de médias, comme l'histoire de instagram, les postses de instagram et les postes de facebook. Suivi mon page de instagram pour quelque développements passionnant pour mes designs @mini.interface.",
                credits:"Photo par Sara Kurfeß sur Unsplash"
            },
            { 
                imgsrc:"https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
                heading:"Développements",
                text:"Maintenant, je utilise quelque outils, par exemple, ReactJS et NodeJS pour développement frontend et backend.",
                credits:"Photo par Clément H sur Unsplash"
            },
            {
                imgsrc:"https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=786&q=80",
                heading:"Design du Web",
                text:"Je vais pouvoir à collaborer avec vous à trouver une site web adaptif pour votre site. En plus, je utilise outils, par exemple, Bootstrap, Material UI et Semantic UI pour design du web.",
                credits:"Photo par Ben Kolde sur Unsplash"
    
            }

        ]
    }
}

function Projects ({classes}) {
    const {language}= useContext(LanguageContext);
    const {isDarkMode}= useContext(ThemeContext);
    const {content}=cards[language]
    return (
        <div className={classes.ProjectsGrid} style={{backgroundColor:isDarkMode?"black":"#f6f6f6"}}>
            <Fade bottom>
            <div className={classes.ProjectsContent}>
                {content.map(card=> <ProjectContent key={uuid()} image={card.imgsrc} heading={card.heading} text={card.text} credit={card.credits}/>)}

            </div>
            </Fade>
        </div>
    )
}

export default withStyles(styles)(Projects)