import React, {useContext} from 'react';
import uuid from "uuid/v4";
import AboutText from './AboutText';
import AboutTextPt2 from './AboutTextPt2'
import TypeWriter from 'react-animate-typewriter'
import Fade from 'react-reveal/Fade'
import AboutMeImg from './images/aboutme-img.png'
import withStyles from "@material-ui/core/styles/withStyles";
import styles from './styles/AboutMeStyles.js'
import {LanguageContext} from './contexts/LanguageContext'
import {ThemeContext} from './contexts/ThemeContext'

const content={
    english:{
        entries:[
            {
                heading:"my journey into design/",
                smallheading:null,
                text:"I am a Singapore-based designer dipping her toes into the design industry. More than design, I believe that design is branding, and I am passionate about conveying the right branding to the wider public. Previously, I worked with a UK-based social enterprise, SchoolX, as the Media Head in charge of Public Relations and Branding.",
                img: null
            },
            {
                heading:"my design philosophy/",
                smallheading:null,
                text:"I am in love with minimalist, geometric designs, simple yet impactful at the same time. Usually I play with contrasts, colour and shapes, these bring out the soul of the individual or the organisation.",
                img: null
            }
        ],
        opportunities:[
            {
                smallheading:"MENTORSHIP",
                text:"I am looking for a mentor! Being self-taught in design, I am looking for mentors in web design and development and with experience working with social enterprises.",
                icon:"fas fa-handshake fa-3x"

            },
            {
                smallheading:"CHARITIES/SOCIAL ENTERPRISES",
                text:"I am keen to collaborate with charities, social enterprises and startups to contribute my skills for the benefit of both parties. Please submit an enquiry if you are interested.",
                icon:"fas fa-hand-holding-heart fa-3x"
            }
        ],
    future_work:[
        {
            smallheading:"MOBILE APPLICATION",
            text:"I am working on an exciting project relating to the healthcare industry. Stay tuned for more updates!",
            icon:"fas fa-capsules fa-3x"
        },
        {
            smallheading:"WEB APPLICATION",
            text:"I am thinking of doing a web application for freelancers, stay tuned!",
            icon:"far fa-window-restore fa-3x"
        }
    ]
    },
    français:{
        entries:[
            {
                heading:"mon voyage dans désigns/",
                smallheading:null,
                text:"Je suis un Singapourien designer dans industrie des désigns. Je crois que les désigns sont l'images que marque, et je suis très passionnée sur donner le vraiment l'image que marque pour tout les gens. Avant, j'ai travillé avec un l'enterprise de Royame-uni comme le chef de médias pour relations publiques et image de marque.",
                img: null
            },
            {
                heading:"mon philosophie du désign/",
                smallheading:null,
                text:"J'aime beaucoup désigns simples et mathématique. Je crois que ces désigns sont très simple mais émouvant. En général, je joue avec les contrastes, couleur et formes, qui donnent le seule du personne ou de l'énterprise.",
                img: null
            }
        ],
        opportunities:[
            {
                smallheading:"ÉTUDES",
                text:"Je trouve un enseignant! Comme j'appendre seule dans désigns, je trouve pour enseignants du créations de sites web et développement web. Il/elle dois avoir déja à travailler avec l'énterprises sociale.",
                icon:"fas fa-handshake fa-3x"

            },
            {
                smallheading:"LES ORGANISMES DE BIENFAISANCE/L'ÉNTERPRISES SOCIALE",
                text:"Je suis intéressé à collaborer avec les organismes de bienfaisance, l'énterprises sociale et startups pour contribuer mon compétences pour deux parties. Si vous êtes interessé, soumettre une demande s'il vous plaiît.",
                icon:"fas fa-hand-holding-heart fa-3x"
            }
        ],
    future_work:[
        {
            smallheading:"L'APPLICATION DE MOBILE",
            text:"Je travaille sur un projet dans l'industrie de santé.",
            icon:"fas fa-capsules fa-3x"
        },
        {
            smallheading:"L'APPLICATION DE WEB",
            text:"Je pense à faire un application de web pour les travailleurs indépendent.",
            icon:"far fa-window-restore fa-3x"
        }
    ]

    }
}

const headings={
    english:{
        heading1:"DESIGN.IS.BRANDING.",
        heading2:"opportunities/",
        heading3:"future work/"
    },
    français:{
        heading1:"J'AIME.LES.DESIGNS",
        heading2:"opportunités/",
        heading3:"travail futur/"
    }

}

// Photo by Nikolai Chernichenko on Unsplash
function AboutMe ({classes}) {
    const {language}= useContext(LanguageContext);
    const {isDarkMode} = useContext(ThemeContext);
    const {entries, opportunities,future_work}=content[language]
    const {heading1, heading2,heading3}=headings[language]
    return ( 
        <div style={{background:"inherit",position:"absolute",textAlign:"center",paddingTop:"5rem"}}>
        <div style={{width:"100vw"}}>
            <p className={classes.AboutMark} style={{color:isDarkMode?"white":"black"}}>"</p>
            <TypeWriter typing={1} className={classes.Heading}>
                {heading1}
            </TypeWriter>

            <Fade>
            <div className={classes.about1Flex}>
                {entries.map(entry=>
                <AboutText key={uuid()} heading={entry.heading} smallheading={entry.smallheading} text={entry.text}/>)}
            </div>
            </Fade>

            <Fade>
            <div className={classes.about2Flex}>
                <div>
                    <h2 style={{color:isDarkMode?"white":"#434343"}}>{heading2}</h2>
                    <div className={classes.about2Flex1}>
                        {opportunities.map(opportunity=>
                        <AboutTextPt2 key={uuid()} heading={heading2} smallheading={opportunity.smallheading} text={opportunity.text} icon={opportunity.icon}/>)}
                    </div>
                </div>
                <div>
                    <h2 style={{color:isDarkMode?"white":"#434343"}}>{heading3}</h2>
                    <div>
                        {future_work.map(work=>
                        <AboutTextPt2 key={uuid()} heading={heading3} smallheading={work.smallheading} text={work.text} icon={work.icon}/>)}
                        </div>
                </div>
            </div>
            </Fade>
            <div>
            {!isDarkMode&&<img className={classes.aboutMeImg} alt="background" src={AboutMeImg} />}
            </div>
        </div>
        </div>

    )
}

export default withStyles(styles)(AboutMe)