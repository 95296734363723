import React,{useContext} from 'react';
import styles from './styles/AboutTextStyles.js'
import withStyles from "@material-ui/core/styles/withStyles";
import {ThemeContext} from './contexts/ThemeContext'


function AboutText ({classes,heading,text}) {
    const {isDarkMode} = useContext(ThemeContext);
    return(
        <div className={classes.About}>
            <h2 style={{color:isDarkMode?"white":"#434343"}} className={classes.AboutHeading}>{heading}</h2>  
            <p style={{color:isDarkMode?"white":"#6C6C6C"}} className={classes.AboutText}>{text}</p>

        </div>

    )
}

export default withStyles(styles)(AboutText)