import app from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD0OExSLzeSxJIueNbEaqN_V0yhzL2S4iA",
  authDomain: "mini-interface.firebaseapp.com",
  databaseURL: "https://mini-interface.firebaseio.com",
  projectId: "mini-interface",
  storageBucket: "mini-interface.appspot.com",
  messagingSenderId: "916204087486",
  appId: "1:916204087486:web:651546ccf4852a4743d6ef",
  measurementId: "G-3CF6JCXMDQ"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth=app.auth();
  }


// Auth API
doCreateUserWithEmailAndPassword = (email, password)=> 
  this.auth.createUserWithEmailAndPassword(email, password)

doSignInWithEmailAndPassword = (email, password)=> 
this.auth.signInWithEmailAndPassword(email, password)

doSignOut = () => this.auth.signOut();

doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

doPasswordUpdate=password=> this.auth.currentUser.updatePassword(password);

}


export default Firebase;