import sizes from "./sizes"

const styles=theme=> ({
    contactHeading:{},
    contactHeading1:{},
    contactHeading2:{},
    contactBody:{
        backgroundColor:"inherit",
        position: "absolute",
        paddingTop:"5rem",
        width:"100% !important",
        height:"100% !important",
        overflow:"auto",
        paddingBottom:"1rem",
    [sizes.down("lg")]:{
        display:"grid",
        gridTemplateColumns:"1fr"
    },
        "& $contactHeading":{
            textAlign:"center",
            fontFamily:"Helvetica Neue,sans-serif",
        },
        "& $contactHeading1": {
            fontSize:"3vw",
            fontWeight:"100",
            [sizes.down("lg")]: {
                fontSize:"8vw",
            }
        },
        "& $contactHeading2": {
            fontSize:"2vw",
            fontWeight:"200",   
            [sizes.down("lg")]: {
                fontSize:"4vw"
            }
        }

    },
    contactGrid:{
        display:"grid",
        gridTemplateColumns:"repeat(2,1fr)",
        [sizes.down("lg")]:{
            display:"grid",
            gridTemplateColumns:"1fr"
        }
    },
    contactImg:{
        width:"60%",
        display:"block",
        margin:"0 auto",
        marginBottom:"2rem"

    },
    contactFormBody:{
        width:"80%",
        margin:"0 auto",
    },
    contactForm:{
        alignSelf:"center",
        padding:"5%",
        display:"flex",
        justifyContent:"space-evenly",
        flexDirection:"column"
    },
    TextField:{
        margin:theme.spacing(1)
    },
    button:{
        margin:theme.spacing(1)
    }
})

export default styles; 