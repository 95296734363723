export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const SIGN_OUT = '/signout';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const MEMBERS="/members"

export const LANDING = '/';
export const HOME = '/myhomepage';
export const ABOUT = '/about';
export const PROJECTS = '/projects';
export const CONTACT = '/contact';