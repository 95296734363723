import React from 'react';
import styles from "./styles/ProjectContentStyles.js"
import withStyles from "@material-ui/core/styles/withStyles";


function ProjectContent ({classes, heading, image, text, credit}){
    return (
        <main className={classes.main}> 
            <div className={classes.ProjectContent}>
                <img className={classes.Img} src={image} alt={heading}/>
                <h2 className={classes.Underline}>{heading}</h2>
                <div className={classes.Text}>
                    <p>{text}</p>
                    <p>{credit}</p>
                </div>
            </div>
        </main>

    )
}

export default withStyles(styles)(ProjectContent)