import React,{useContext} from 'react';
import styles from "./styles/AboutTextPt2Styles.js"
import withStyles from "@material-ui/core/styles/withStyles";
import {ThemeContext} from './contexts/ThemeContext'

function AboutTextPt2 ({classes, smallheading,icon,text}) {
    const {isDarkMode} = useContext(ThemeContext);
    return(
        <div>
            <h3 style={{color:isDarkMode?"white":"#434343"}} className={classes.classSmallHeading}>{smallheading}</h3>
            <div style={{display:"grid", gridTemplateColumns: "1fr 3fr"}}>
                <i style={{color:isDarkMode?"white":"black"}} className={icon} />
                <p style={{color:isDarkMode?"white":"#6C6C6C"}} className={classes.classText}>{text}</p>
            </div>
            
        </div>

    )
}

export default withStyles(styles)(AboutTextPt2)