import sizes from "./sizes"

export default {
    ProjectsGrid:{
        position: "absolute",
        height:"100%"
    },

    ProjectsContent: {
        backgroundColor:"inherit",
        paddingTop:"8rem",
        display:"grid",
        gridTemplateColumns: "repeat(3,1fr)",

    [sizes.down("lg")]:{
        gridTemplateColumns: "repeat(2,1fr)",
    },
    [sizes.down("xs")]:{
        gridTemplateColumns: "repeat(1,1fr)",
    }
}
    
}