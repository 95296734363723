export default {

    Button:{
    backgroundColor: "inherit",
    border: "none",
    fontSize: "1.1em",
    margin:"2rem",
    fontFamily: "Helvetica Neue,sans-serif",
    color: "inherit",
    textDecoration: "none",
    cursor: "pointer",
    transition: "all ease-in-out 0.5s",
    "&:hover":{
        color:"inherit",
        fontSize:"1.4em"
    }
    }

}