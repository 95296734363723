import sizes from "./sizes"

export default {
    main:{
        
        margin:"1rem",
        display:"flex",
        justifyContent:"space-evenly",
        [sizes.down("xs")]:{
            width:"90vw"
        }
    },
    ProjectContent: {
        padding:"2rem",
        backgroundColor:"white !important",
        width:"90%",
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        boxShadow:"0 10px 10px rgba(0, 0, 0, 0.1)",
        "&:hover": {
            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.3)"
        },
        // [sizes.down("xs")]:{
            
        // }
    
    },
    Img :{
        width:"300px",
        height:"300px",
        borderRadius: "50%",
        boxShadow: "0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.1)",
        transition: "0.4s filter ease",
        [sizes.down("lg")]:{
            width:"250px",
            height:"250px"
        }
    },
    
    Underline: {
        border: "4px solid transparent",
        display: "inline-block",
        verticalAlign: "top",
        lineHeight: "36px",
        textTransform: "uppercase",
        color: "black",
        letterSpacing: "0.2em",
        textAlign: "center",
        fontSize: "1.4rem",
        margin: "10px",
        position: "relative",
        fontWeight: "bold",
        align:"center"
    },

    Text : {
        fontFamily: "Helvetica Neue,sans-serif",
        fontSize:"1.2rem",
        "& p:nth-child(2)" :{   
            fontWeight: "bold"
        }
    }

}