import sizes from "./sizes"
const styles=theme=> ({
    NavBarForm: {
        display:"flex",
        justifyContent:"space-between",
        minWidth: 120,
    },
    NavIcon:{
        [sizes.down("lg")]:{
            cursor:"pointer"
        }
    },
    NavLinks:{
        [sizes.down("lg")]:{
            display:"none"
        }
    },
    NavTab:{
        fontSize: "1.1em",
        fontFamily: "Helvetica Neue,sans-serif",
        margin:"2rem",
        transition: "all ease-in-out 0.5s",
        "&:hover":{
            color:"inherit",
            fontSize:"1.4em"
        }

    },
    HiddenDrawer:{

        [sizes.up("lg")]:{
            display:"none"
        }
    }

});
export default styles;