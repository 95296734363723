import React,{useContext} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {TextField,Paper,Button, Typography} from "@material-ui/core";
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import contactpageImg from './images/contactpage-img1.png';
import {LanguageContext} from './contexts/LanguageContext'
import {ThemeContext} from './contexts/ThemeContext'
import styles from './styles/ContactStyles.js'
import emailjs from 'emailjs-com'


// Need to add a onChange function

const content={
    english:{
        heading:"GET IN TOUCH",
        location:" singapore | ",
        contact_email:" mini.interface@gmail.com | ",
        company_name:" mini.interface",
        form_title:"Send Your Enquiry",
        name_field:"Your name",
        email_field:"Your email",
        message_field:"Your message",
        send_field:"Send"

    },
    français:{
        heading:"CONTACTEZ NOUS",
        location:" singapour | ",
        contact_email:" mini.interface@gmail.com | ",
        company_name:" mini.interface",
        form_title:"Envoyez Votre Demande",
        name_field:"Votre nom",
        email_field:"Votre addresse électronique",
        message_field:"Votre message",
        send_field:"Envoyez"
    }
}

function Contact ({classes}) {
    const{language}= useContext(LanguageContext);
    const {isDarkMode}=useContext(ThemeContext);
    const {heading,location,contact_email,company_name, form_title,name_field,email_field, send_field, message_field}=content[language]
    const initial_details={
        Name:'',
        Email:'',
        Message:''
      }

    const [details,setDetails]=React.useState(initial_details)

    const handleChange=(evt)=>{
        const value= evt.target.value
        const name=evt.target.name
        setDetails((prevState,evt)=> {
          return({
            ...prevState,
            [name]:value
          })
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        var template_params = {
            "Name": details.Name,
            "Email": details.Email,
            "Message": details.Message
         }
         
        var service_id = "gmail";
        var template_id = "template_3RAGbp46";
        let user_id="user_6iRHFiDTrxffaD72TQ3Gg";
        emailjs.send(service_id, template_id, template_params,user_id)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
            }, (err) => {
                console.log('FAILED...', err);
            });
        setDetails((prevState,evt)=> {
            return({
                Name:"",
                Email:"",
                Message:""
            })
            })
       
    }

    return (
        <div className={classes.contactBody}>
            <div className={classes.contactHeading}>
                <h1 className={classes.contactHeading1} style={{color:isDarkMode?"white":"#434343"}}>{heading}</h1>
                <h2 className={classes.contactHeading2} style={{color:isDarkMode?"white":"#6C6C6C"}} > <i className="fas fa-map-marker-alt"/>{location}<i className="fas fa-envelope" />{contact_email}<i className="fab fa-instagram"/>{company_name}</h2>
            </div>
            <div className={classes.contactGrid}>
                <div >
                <img className={classes.contactImg} alt={contactpageImg} src={contactpageImg}/>
                </div>
                <div style={{paddingBottom:"3rem"}}>
                <Paper className={classes.contactFormBody} elevation={3}>
                <form className={classes.contactForm} onSubmit={handleSubmit}>

                    <Typography variant="h4">{form_title}</Typography>
                    <TextField 
                    className={classes.TextField}
                    id="standard-basic"
                    label={name_field}
                    name="Name"
                    onChange={handleChange}
                    value={details.Name}>
                        {name_field}
                    </TextField>

                    <TextField 
                    className={classes.TextField}
                    id="standard-basic"
                    label={email_field}
                    name="Email"
                    onChange={handleChange}
                    value={details.Email}>
                        {email_field}
                    </TextField>

                    <TextField
                    className={classes.TextField}
                    multiline
                    rows={4}
                    label={message_field}
                    name="Message"
                    onChange={handleChange}
                    value={details.Message}
                    >
                        {message_field}
                    </TextField>

                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<SendRoundedIcon/>}
                        type="submit"
                    >
                    {send_field}
                    </Button>
                </form>
                </Paper>
                </div>
                </div>
        </div>
        
    )
}

export default withStyles(styles)(Contact)