import sizes from "./sizes"


export default {
    AboutMark: {
        fontFamily:"Avenir Next",
        display:"inline",
        fontSize:"20vw",
        zIndex:"2",
        position: "relative",
        [sizes.down("lg")]:{
            fontSize: "30vw"
        },
        [sizes.down("xs")]:{
            fontSize: "30vw"
        }
    },
    Heading: {
        width:"90%",
        fontWeight:"bold",
        color:"#F6F6F6", 
        textShadow:"2px 2px 17px rgba(0,0,0,0.5)",
        fontSize:"8vw",
        display:"inline",
        fontFamily:"Helvetica Neue, sans-serif",
        zIndex:"1",
        position:"relative",
        wordWrap:"break-word !important",
        [sizes.down("lg")]:{
            fontSize:"16vw",
            overflowWrap:"break-word !important"
        },
        [sizes.down("xs")]: {
            fontSize:"16vw",
            overflowWrap:"break-word !important"
        }

    },
    

    about1Flex: {
        zIndex:"8",
        position: "relative",
        display:"grid",
        gridTemplateColumns: "repeat(2,1fr)",
        paddingBottom:"2rem",
        [sizes.down("lg")]:{
            gridTemplateColumns:"1fr"
        },
        [sizes.down("xs")]:{
            gridTemplateColumns:"1fr"
        }
    },
    about2Flex: {
        zIndex:"9",
        position: "relative",
        display:"grid",
        gridTemplateColumns: "repeat(2,1fr)",
        [sizes.down("lg")]:{
            gridTemplateColumns:"1fr"
        },
        [sizes.down("xs")]:{
            gridTemplateColumns:"1fr"
        },
        "& div": {
            "& h2":{
                fontWeight:"bold",
                color:props=> props.isDarkMode===true?"#434343":"#F6F6F6",
                fontFamily:"Helvetica Neue, sans-serif",
                fontSize:"2vw",
                padding:"1rem",
                [sizes.down("lg")]:{
                    fontSize:"5vw"
                },
                [sizes.down("xs")]:{
                    fontSize:"7vw"
                }
            }

        }
        
    },
    aboutMeImg:{
        top:"25%",
        left:"48%",
        position:"fixed",
        zIndex:"0"
    }
}
