import React from 'react';
import NavBar from './NavBar'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {ThemeProvider} from './contexts/ThemeContext'
import {LanguageProvider} from './contexts/LanguageContext'
import PageContext from './PageContext'
import Routes from './Routes'
import {withAuthentication} from './session'
import MineeChat from './chat.js'

const theme = createMuiTheme({
  palette: {
    primary: {
        main: '#212121'
      },
    secondary:{
      main:"#fafafa"
    }
    }
  },
)

// Link acts as replacement to <a> tags
const App=()=> {
  return (
    <>
    <ThemeProvider>
      <LanguageProvider>
      <MuiThemeProvider theme={theme}>
        <NavBar />
        <PageContext>  
            <Routes />
            <MineeChat />
        
        </PageContext>
        </MuiThemeProvider>
      </LanguageProvider>
      </ThemeProvider>
      </>
  )
}

export default withAuthentication(App);

