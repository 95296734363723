import React, {useContext} from 'react';
import {NavLink} from 'react-router-dom';
import Pulse from 'react-reveal/Pulse'
import {Button} from "@material-ui/core";
import landingpageImg from './images/landingpage-img.png'
import withStyles from "@material-ui/core/styles/withStyles";
import styles from './styles/LandingPageStyles.js'
import {LanguageContext} from './contexts/LanguageContext'
import {ThemeContext} from './contexts/ThemeContext'


const content={
    english:{
        small_text:"minimalist | geometric | bold",
        button:"DISCOVER",
        credit:"Photo by Sharon Pittaway on Unsplash"
    },
    français:{
        small_text:"minimaliste | géométrique | audacieuse",
        button:"DÉCOURVER",
        credit:"Photo par Sharon Pittaway sur Unsplash"
    }
}

function LandingPage ({classes}) {
    const{language}= useContext(LanguageContext);
    const {isDarkMode}=useContext(ThemeContext)
    const {small_text,credit,button}=content[language];
    return (
        <div style={{justifyContent:"center",alignItems:"center", display:"flex", background:"inherit",width:"100vw",height:"100vh"}}>  
            <div className={classes.LandingPageContent}> 
                <img className={classes.LandingPageImg} alt="flower" src={landingpageImg} />
                <h2 style={{color:isDarkMode?"white":"#6C6C6C"}}>mini</h2>
                <h1> INTERFACE</h1>
                <Pulse>
                <NavLink className={classes.LandingPageBtnTxt} exact activeClassName="active-link" to="/about">
                    <Button color={!isDarkMode?"primary":"secondary"} size="large" variant="outlined">
                        {button}
                    </Button>
                    </NavLink>
                </Pulse>
                <p style={{color:isDarkMode?"white":"#434343"}} className={classes.LandingPageSmallText}>{small_text}</p>  
            </div>      
            <div className={classes.FixedText}>{credit}</div>                        
        </div>
        
    )
}

export default withStyles(styles)(LandingPage)