import React from 'react';
import {PasswordForgetForm} from '../userAuth/PasswordForgetPage';
import PasswordChangeForm from '../userAuth/PasswordChangePage';
import styles from '../styles/AccountPageStyles'
import { Paper,Typography,withStyles} from '@material-ui/core';


const AccountPage=({classes})=>(
    <div className={classes.AccountPageTotal}>
        <Paper className={classes.AccountPage}>
        <Typography>
        <h1>Account Page</h1>
        </Typography>
        <div className={classes.AccountForm}>
            <PasswordForgetForm classes={classes}/>
            <PasswordChangeForm />
        </div>
        </Paper>
    </div>
)

export default withStyles(styles)(AccountPage)