import React,{Component} from 'react';

class MineeChat extends Component {
    componentDidMount(){
        (function(d, m){
            var kommunicateSettings = {"appId":"4774aa367b4165e5389ab340c07b8a19","popupWidget":true,"automaticChatOpenOnNavigation":true};
            var s = document.createElement("script"); s.type = "text/javascript"; s.async = true;
            s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
            var h = document.getElementsByTagName("head")[0]; h.appendChild(s);
            window.kommunicate = m; m._globals = kommunicateSettings;
          })(document, window.kommunicate || {});
    }
    render(){
        return(
            <div></div>
        )
    }
}

export default MineeChat;