export default {
    AccountPageTotal:{
        backgroundColor:"inherit",
        position: "absolute",
        width:"100% !important",
        height:"100% !important",
        
    },
    AccountPage:{
        marginTop:"10rem",
        width:"70%",    
        margin:"0 auto",
        textAlign:"center",
        justifyContent:"center",
    },
    AccountForm: {
        flexDirection:"column",
        textAlign:"center",
        display:"flex"
    }
}